/* Estilo general del pie de página */
.footer {
    background-color: #333;
    color: #fff;
    padding: 50px 0;
    text-align: center;
  }
  
  /* Logo de la empresa */
  .logo {
    max-width: 200px;
    height: auto;
    margin-bottom: 20px;
  }
  
  /* Menú */
  .list-unstyled {
    list-style: none;
    padding: 0;
  }
  
  .list-unstyled li {
    margin-bottom: 10px;
  }
  
  .list-unstyled a {
    text-decoration: none;
    color: #fff;
    transition: color 0.3s ease;
  }
  
  .list-unstyled a:hover {
    color: #f0f0f0;
  }
  
  /* Suscripción al newsletter */
  .form-control {
    background-color: #444;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
  }
  
  .form-control:focus {
    border-color: #555;
    box-shadow: none;
  }
  
  .btn-primary {
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  /* Enlaces de la parte inferior */
  .text-light a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .text-light a:hover {
    color: #f0f0f0;
  }
  
  /* Derechos de autor y enlaces */
  .mb-0 {
    margin-bottom: 0;
  }
  
  .mx-2 {
    margin-left: 10px;
    margin-right: 10px;
  }

  
  /* Efecto hover para iconos de redes sociales */
.icon {
    transition: transform 0.3s ease;
  }
  
  .icon:hover{
    transform: scale(1.1); /* Aumentar el tamaño al pasar el cursor */
    color:#FFA500;
  }