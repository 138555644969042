.diagrama{
    width: 50%;
}

.list-group .rounded-circle {
    width: 40px; /* Ajusta el tamaño del círculo */
    height: 40px; /* Ajusta el tamaño del círculo */
    aspect-ratio: 1/1; /* Asegura que la relación de aspecto sea 1:1 (círculo perfecto) */
    text-align: center;
}

.flex-fill{
    font-weight: bold;
}