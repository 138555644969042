.carousel {
    height: 100vh;
    max-height: 600px; /* Limita la altura máxima a 700 píxeles */
  }
  
  .carousel-item {
    height: 100vh;
  }
  
  .carousel-inner {
    height: 100%;
    display: flex; /* Activa el diseño flexible */
    flex-direction: column; /* Organiza los elementos en columna */
    align-items: stretch; /* Extiende los elementos para ocupar toda la altura disponible */
  }
  