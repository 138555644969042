.scroll-to-top {
  position: fixed; /* Fijar el botón en la posición */
  bottom: 20px; /* Distancia desde la parte inferior */
  right: 20px; /* Distancia desde la derecha */
  z-index: 100; /* Asegurarse de que el botón esté sobre otros elementos */
  width: 50px; /* Ajustar el ancho del botón */
  height: 50px; /* Ajustar la altura del botón */
  border-radius: 50%; /* Redondear las esquinas */
  background-color: rgb(233, 42, 194); /* Color de fondo */
  color: #fff; /* Color del texto */
  text-align: center; /* Centrar el texto */
  line-height: 50px; /* Alinear verticalmente el texto */
  cursor: pointer; /* Indicar que se puede hacer clic */
  transition: background-color 0.3s ease; /* Animación de cambio de color al pasar el cursor */
}

.scroll-to-top:hover {
  background-color: #2980b9; /* Color de fondo al pasar el cursor */
}

.scroll-to-top .btn-icon {
  display: inline-block; /* Permitir que el icono se muestre en línea */
  width: 20px; /* Ajustar el ancho del icono */
  height: 20px; /* Ajustar la altura del icono */
  margin-top: 5px; /* Añadir espacio superior al icono */
}
