.icono_ {
    font-size: 100px;
    color: rgb(233, 42, 194);
}

.contenedor_ {
    background-color: rgb(4, 87, 141);
}

.voletar{
    transform: rotate(180deg);
}