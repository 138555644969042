nav.navbar {
    background-color: #673AB7 !important; /* Color morado */
  }
  
  .navbar-brand, .navbar-nav .nav-link {
    color: white !important; /* Texto blanco para que contraste */
  }
  
  .navbar-toggler {
    border-color: white !important; /* Color blanco para el botón de hamburguesa */
  }

/* Style the navbar toggler icon when expanded (menu open) */
.navbar-toggler.collapsed .navbar-toggler-icon::after {
  content: ""; /* Remove default arrow */
  display: block;
  width: 10px;
  height: 10px;
  border-width: 2px solid #007bff; /* Set desired icon color (blue in this case) */
  border-top-style: none;
  border-bottom-style: none;
  transform: rotate(45deg);
}

/* Style the navbar toggler icon when collapsed (menu closed) */
.navbar-toggler .navbar-toggler-icon::after {
  content: ""; /* Remove default arrow */
  display: block;
  width: 10px;
  height: 10px;
  border-width: 2px solid #007bff; /* Set desired icon color (blue in this case) */
  border-top-style: none;
  border-bottom-style: none;
  transform: rotate(-90deg);
}
