.hr-custom {
    width: 50%; /* Adjust width as needed */
    height: 10px; /* Adjust thickness as needed */
    background-color: #FFA500; /* Hot pink to mustard yellow */
    border: none;
    margin: 20px auto; /* Center and add margin */
    opacity: 100%;
}

.parallax {
    /* The image used */
    background-image: url("../../../public/img/fondo1.jpg");
    /* Set a specific height */
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative; /* Make parallax div position relative */
  min-height: 500px; /* Set a minimum height */
}
.parallax2 {
    /* The image used */
    background-image: url("../../../public/img/fondo2.jpg");
    /* Set a specific height */
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative; /* Make parallax div position relative */
  min-height: 500px; /* Set a minimum height */
}

.parallax3 {
    /* The image used */
    background-image: url("../../../public/img/fondo3.jpg");
    /* Set a specific height */
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative; /* Make parallax div position relative */
  min-height: 500px; /* Set a minimum height */
}

.parallax-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .title-color{
    text-shadow: 0 0 5px #fff;
}